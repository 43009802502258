import { render, staticRenderFns } from "./dataReporting.vue?vue&type=template&id=71d8e24c&scoped=true"
import script from "./dataReporting.vue?vue&type=script&lang=js"
export * from "./dataReporting.vue?vue&type=script&lang=js"
import style0 from "./dataReporting.vue?vue&type=style&index=0&id=71d8e24c&prod&lang=scss"
import style1 from "./dataReporting.vue?vue&type=style&index=1&id=71d8e24c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d8e24c",
  null
  
)

export default component.exports